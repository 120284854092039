body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
	background: linear-gradient(-45deg, #e06192, #8454fd, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1{
  font-size: 4em;
  font-weight: 800;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}

.tagline{
  font-size: 2em;
}

.text-container{
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;            /* Enable Flexbox */
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
  flex-direction: column;   /* Stack the items vertically */
  font-family: 'Inter', sans-serif;
}

.center{
  width: 100%;
  text-align: center;
  display: block;
}

